* {
    font-family: 'AkzidenzGroteskBE', sans-serif;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

@font-face {
    font-family: 'AkzidenzGroteskBE';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/AkzidGroCFFLig.woff2') format('woff2');
}

@font-face {
    font-family: 'AkzidenzGroteskBE';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/AkzidGroCFFReg.woff2') format('woff2');
}

@font-face {
    font-family: 'AkzidenzGroteskBE';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/AkzidGroCFFMd.woff2') format('woff2');
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,light,extra-light,italic&subset=latin,latin-ext');

html {
    cursor: unset;
}

::selection {
    background-color: #b3d4fc;
}

* a {
    color: inherit;
}

* button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    color: inherit;
    font: inherit;

    line-height: normal;
    text-transform: unset;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    -webkit-appearance: none;
}

* ::-moz-focus-inner {
    border: 0;
    padding: 0;
}